<template>
  <div class="row rest">
    <div class="rest aside-bg"></div>
    <ThinqRefSnb class="left-container" />
    <article class="right-container">
      <hgroup><h1 id="common-header">공통 | Headers</h1></hgroup>
      <table class="simple-table cell1-nowrap">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
          <th>Default</th>
        </tr>
        <tr>
          <td>Authorization</td>
          <td>String</td>
          <td>Mandatory</td>
          <td>
            <ul>
              <li>
                LG 회원 플랫폼(EMP)에서 사용자 인증을 위해 OAuth 표준에 따라
                발급하는 토큰 값입니다.
              </li>
              <li>
                이 토큰을 이용하여 LG ThinQ 플랫폼은 어느 사용자의 디바이스
                정보를 제공해야 하는지 식별하고, 서비스가 해당 사용자의 디바이스
                정보를 이용하도록 승인 받았는지 확인합니다. Bearer {token}
              </li>
            </ul>
          </td>
          <td>None</td>
        </tr>
        <tr>
          <td>x-country-code</td>
          <td>String</td>
          <td>Fixed</td>
          <td>
            <ul>
              <li>서비스를 제공할 국가를 지정할 수 있습니다.</li>
              <li>국가코드 ISO 3166-1 alpha-2를 사용합니다.</li>
              <li>현재는 한국만 지원합니다.</li>
            </ul>
          </td>
          <td>KR</td>
        </tr>
        <tr>
          <td>x-message-id</td>
          <td>String</td>
          <td>Option</td>
          <td>
            <ul>
              <li>
                ThinQ 플랫폼으로 요청되는 정보를 추적하기 위한 값 입니다. 특정
                API의 흐름을 추적하고 에러 발생 시 원인을 찾을 수 있습니다.
              </li>
              <li>
                생성 규칙 - url-safe-base64-no-padding (UUID Version 4)방법으로
                생성합니다. - 길이는 22자입니다.
              </li>
              <li>코드 예시 (Python)</li>
            </ul>
            <pre><code class='hljs'>import uuid
import base64
messageId = base64.urlsafe_b64encode(uuid.uuid4().bytes)[:-2]</code></pre>
          </td>
          <td>None</td>
        </tr>
      </table>
      <h2>Headers 예시</h2>
      <div>
        <pre><code class='hljs'>{
    "Authorization": "Bearer 5a9a713f51a95c53d781addd1af0dfa4f6e1e7420a8bff3c5198308dac571aa9845832b8d29bbe1f04deec2d35229c6d",
    "x-country-code": "KR",
    "x-message-id": "0123456789012345678912"
}</code></pre>
      </div>

      <hgroup><h1 id="common-res">공통 | Response</h1></hgroup>
      <h2>HTTP 응답 코드</h2>
      <h3>HTTP 표준 응답 코드 규약</h3>
      <div>
        ThinQ Connect API의 응답 코드는 다음과 같은 HTTP 표준 응답 코드 규약에
        따라 정의됩니다.
      </div>
      <table class="simple-table">
        <tr>
          <th>HTTP Response Code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>2xx</td>
          <td>성공</td>
        </tr>
        <tr>
          <td>4xx</td>
          <td>에러 (클라이언트에서 요청이 잘못된 경우)</td>
        </tr>
        <tr>
          <td>5xx</td>
          <td>에러 (서버에서 오류가 발생한 경우)</td>
        </tr>
      </table>
      <h3>ThinQ Connect API에서 사용하는 HTTP 응답코드</h3>
      <div>
        ThinQ Connect API에서 사용하는 응답 코드는 다음과 같으며, 매 요청마다
        다음 중 적절한 응답 코드를 반환합니다.
      </div>
      <table class="simple-table">
        <tr>
          <th>HTTP Response Code</th>
          <th>Text Message</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>200</td>
          <td>OK</td>
          <td>정상 응답</td>
        </tr>
        <tr>
          <td>400</td>
          <td>Bad request</td>
          <td>잘못된 요청임</td>
        </tr>
        <tr>
          <td>401</td>
          <td>Unauthorized</td>
          <td>권한이 없음</td>
        </tr>
        <tr>
          <td>404</td>
          <td>Not found</td>
          <td>요청받은 리소스를 찾을 수 없음</td>
        </tr>
        <tr>
          <td>500</td>
          <td>Internal server error</td>
          <td>서버 에러임</td>
        </tr>
        <tr>
          <td>501</td>
          <td>Not implemented</td>
          <td>아직 구현되지 않음</td>
        </tr>
        <tr>
          <td>503</td>
          <td>Service unavailable</td>
          <td>사용할 수 없는 서비스임</td>
        </tr>
      </table>
      <h2>응답 메시지</h2>
      <h3>공통 메시지</h3>
      <table class="simple-table">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Messageid</td>
          <td>String</td>
          <td>
            요청 시 헤더에 포함된 X-Message-ID 값입니다. 이 값을 응답 메시지에
            포함시켜서 문제가 있을 때 확인할 수 있도록 합니다.
          </td>
        </tr>
        <tr>
          <td>Timestamp</td>
          <td>String</td>
          <td>
            요청이 들어왔을 때의 시간을 의미하며 ISO 8601 Format을 따릅니다.
          </td>
        </tr>
      </table>
      <h3>예시</h3>
      <pre><code class="hljs">{
    "messageId": "fNvdZ1brTn-wWKUlWGoSVw",
    "timestamp": "2019-06-25T04:52:18.370732"
}</code>
      </pre>
      <h3>예시 메시지</h3>
      <div>
        API 실행 중 에러가 발생했을 때, 아래 표와 같은 구조로 에러 값을
        반환합니다.
      </div>
      <div></div>
      <table class="simple-table">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Error</td>
          <td>Object</td>
          <td>발생한 에러에 대한 상세 설명을 제공하는 오브젝트</td>
        </tr>
        <tr>
          <td>└ Message</td>
          <td>String</td>
          <td>해당 에러에 대한 간략한 설명</td>
        </tr>
      </table>
      <hgroup><h1 id="common-err">공통 | Error</h1></hgroup>
      <div>
        HTTP의 응답 코드로 확인할 수 없는 에러는 에러 코드를 통해 에러 발생
        원인을 분석할 수 있습니다. 다음은 ThinQ Connect API에서 공통적으로
        사용되는 에러 코드입니다.
      </div>
      <h2>규칙</h2>
      <div>
        ThinQ Connect API의 에러 코드는 클라이언트 에러와 서버 에러로
        구분됩니다.
        <ul>
          <li>클라이언트에게 에러 원인을 알려주기 위해 사용됩니다</li>
          <li>서버 문제로 발생하는 에러를 나타내기 위해 사용됩니다.</li>
        </ul>
      </div>
      <table class="simple-table">
        <tr>
          <th>Error Code</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>1000</td>
          <td>클라이언트 에러</td>
        </tr>
        <tr>
          <td>11xx</td>
          <td>파라미터</td>
        </tr>
        <tr>
          <td>12xx</td>
          <td>리소스</td>
        </tr>
        <tr>
          <td>13xx</td>
          <td>권한</td>
        </tr>
        <tr>
          <td>2000</td>
          <td>서버에러</td>
        </tr>
      </table>
      <h2>
        ThinQ Connect API에서 사용하는 에러 코드
      </h2>
      <h3
        v-if="!isOpenErrorCodeList"
        @click="showErrorCodeList()"
        id="errorCodeList"
      >
        에러 코드 전체 리스트 보기
      </h3>
      <table class="simple-table hide" ref="errorCodeList">
        <tr>
          <th>Error Code</th>
          <th>Text</th>
          <th>Description</th>
          <th>HTTP Code</th>
        </tr>
        <tr>
          <td>0000</td>
          <td>Unknown error</td>
          <td>정의 되지 않은 에러 (분석해야 하는 에러)</td>
          <td>500</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1000</td>
          <td>Bad request</td>
          <td>잘못된 요청</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1101</td>
          <td>Missing parameters</td>
          <td>필수 입력 항목이 누락됨</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1102</td>
          <td>Unacceptable parameters</td>
          <td>허용하지 않는 파라미터들이 입력됨</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1103</td>
          <td>Invalid token</td>
          <td>토큰 문법이 잘못됨</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1104</td>
          <td>Invalid message id</td>
          <td>메시지 ID의 문법이 잘못됨</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1201</td>
          <td>Not registered admin</td>
          <td>등록된 관리자가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1202</td>
          <td>Not registered user</td>
          <td>등록된 사용자가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1203</td>
          <td>Not registered service</td>
          <td>등록된 서비스가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1204</td>
          <td>Not subscribed event</td>
          <td>구독된 이벤트가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1205</td>
          <td>Not registered device</td>
          <td>등록된 디바이스가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1206</td>
          <td>Not subscribed push</td>
          <td>구독된 푸쉬가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1207</td>
          <td>Already subscribed push</td>
          <td>등록된 푸쉬가 있음 (동일한 푸쉬가 이미 존재함)</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1208</td>
          <td>Not registered service by admin</td>
          <td>관리자가 관리하는 서비스가 아님</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1209</td>
          <td>Not registered user in service</td>
          <td>서비스를 사용하는 사용자가 아님</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1210</td>
          <td>Not registered device in service</td>
          <td>서비스가 허용하는 디바이스 아님</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1211</td>
          <td>Not registered device by user</td>
          <td>사용자가 등록한 디바이스가 아님</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1212</td>
          <td>Not owned device</td>
          <td>사용자가 가지고 있지 않은 디바이스</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1213</td>
          <td>Not registered device</td>
          <td>등록된 디바이스가 없음</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1214</td>
          <td>Not subscribable device</td>
          <td>이벤트 구독을 허용하지 않는 디바이스</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1216</td>
          <td>Incorrect Header</td>
          <td>헤더에 포함된 값이 올바르지 않음</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1217</td>
          <td>Already device deleted</td>
          <td>해당 디바이스가 삭제되었음</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1218</td>
          <td>Invalid token</td>
          <td>유효하지 않은 토큰임</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1219</td>
          <td>Not supported model</td>
          <td>지원하지 않는 제품 모델임</td>
          <td>406</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1220</td>
          <td>Not supported feature</td>
          <td>해당 제품 모델에서는 지원하지 않는 기능임</td>
          <td>406</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1221</td>
          <td>Not supported product</td>
          <td>
            지원하지 않는 임 (해당 서비스 키에 허용된 디바이스 타입이 아님)
            디바이스 타입
          </td>
          <td>406</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1222</td>
          <td>Not connected device</td>
          <td>디바이스가 네트워크에 연결되어 있지 않음</td>
          <td>416</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1223</td>
          <td>Invalid status device</td>
          <td>전달된 디바이스 상태 값이 유효하지 않음</td>
          <td>416</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1224</td>
          <td>Invalid device Id</td>
          <td>허용되지 않은 디바이스 Id</td>
          <td>404</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1301</td>
          <td>Invalid service key</td>
          <td>유효하지 않은 서비스 키</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1302</td>
          <td>Not found token</td>
          <td>만료된 토큰</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1303</td>
          <td>Not found user</td>
          <td>토큰이 발급되지 않은 사용자</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1304</td>
          <td>Not acceptable terms</td>
          <td>서비스 약관 미동의</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1305</td>
          <td>Not allowed api</td>
          <td>허용되지 않은 API</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1306</td>
          <td>Exceeded API calls API</td>
          <td>호출 횟수 초과</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1307</td>
          <td>Not supported country</td>
          <td>지원하지 않는 국가</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1308</td>
          <td>No control autdority</td>
          <td>디바이스 제어권 없음 (tdinQ App에서 사용중)</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1310</td>
          <td>Not supported domain</td>
          <td>제공되지 않은 도메인을 사용한 경우</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1311</td>
          <td>Bad request format</td>
          <td>요청 포맷이 잘못된 경우</td>
          <td>401</td>
        </tr>
        <tr></tr>
        <tr>
          <td>1312</td>
          <td>Exceeded number of event subscription</td>
          <td>해당 서비스 키에 설정된 이벤트 등록 기기 수의 제한을 넘음</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2000</td>
          <td>Internal server error</td>
          <td>내부 서버 에러</td>
          <td>500</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2101</td>
          <td>Not supported model</td>
          <td>지원하지 않은 가전 모델</td>
          <td>503</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2201</td>
          <td>Not provided feature</td>
          <td>지원하는 기능이 아님</td>
          <td>503</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2202</td>
          <td>Not supported product</td>
          <td>지원하는 제품군이 아님</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2203</td>
          <td>Not existent modelJSON</td>
          <td>ModelJSON이 없음</td>
          <td>503</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2205</td>
          <td>Invalid device status</td>
          <td>
            디바이스 상태 정보가 정상적으로 전송되지 않았거나, 전송된 정보가
            정상적으로 파싱되지 않았을 경우
          </td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2207</td>
          <td>Invalid command error</td>
          <td>
            유효하지 않은 제어 명령일 때 (제어 명령에 정의되지 않은 resource,
            property 가 포함된 경우)
          </td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2208</td>
          <td>Fail device control</td>
          <td>디바이스 제어 실패</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2209</td>
          <td>Device response delay</td>
          <td>디바이스 응답 지연</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2210</td>
          <td>Retry request</td>
          <td>상태조회 재요청 필요</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2212</td>
          <td>Syncing ModelJSON</td>
          <td>싱크 중 (에어솔루션 제품)</td>
          <td>412</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2213</td>
          <td>Retry after deleting device</td>
          <td>
            ModelJSON 싱크 실패. 디바이스 삭제 후 재시도 바람 (에어솔루션 제품)
          </td>
          <td>412</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2214</td>
          <td>Fail Request</td>
          <td>요청 실패</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2301</td>
          <td>Command not supported in REMOTE OFF</td>
          <td>
            지원하는 제어 명령이 없는 디바이스의 경우 (remoteControl이 false일
            때)
          </td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2302</td>
          <td>Command not supported in {STATE (Exception Code)}</td>
          <td>
            해당 STATE에서는 해당 제어 명령을 지원하지 않음 예: "Command not
            supported in HEAT (2302)"
          </td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2303</td>
          <td>Command not supported in ERROR</td>
          <td>디바이스가 에러 상태여서 제어가 불가능한 경우</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2304</td>
          <td>Command not supported in POWER OFF</td>
          <td>디바이스 전원이 꺼져있어서 제어가 불가능한 경우</td>
          <td>400</td>
        </tr>
        <tr></tr>
        <tr>
          <td>2305</td>
          <td>Command not supported in {MODE (Exception Code)}</td>
          <td>
            해당 MODE에서는 해당 제어 명령을 지원하지 않음 예: "Command not
            supported in STANDBY (2305)"
          </td>
          <td>400</td>
        </tr>
        <tr></tr>
      </table>
      <h3 v-if="isOpenErrorCodeList" @click="hideErrorCodeList()">
        에러 코드 전체 리스트 닫기
      </h3>
      <h2>에러 메시지 구조</h2>
      API 실행 중 에러가 발생했을 때 아래와 같은 구조로 에러 값을 반환합니다.
      <table class="simple-table">
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Error</td>
          <td>Object</td>
          <td>발생한 에러에 대한 상세 설명을 제공하는 오브젝트</td>
        </tr>
        <tr>
          <td>└ Message</td>
          <td>String</td>
          <td>해당 에러에 대한 간략한 설명</td>
        </tr>
        <tr>
          <td>└ Code</td>
          <td>Enum</td>
          <td>에러 원인을 상세하게 확인하게 위한 Error Code 값</td>
        </tr>
      </table>
      <hgroup><h1 id="device-li">가전 목록 조회</h1></hgroup>
      <h2 class="api-url">GET {Base_URL}/devices</h2>
      <table class="simple-table object">
        <tr>
          <th>Description</th>
          <td>
            사용자가 LG ThinQ 플랫폼에 등록한 가전 목록을 얻어오기 위한
            API입니다. 다른 메서드들을 사용하기 전에 반드시 한 번은 호출되어야
            하며, 가전 목록을 한 번 얻어온 후에는 매번 호출할 필요는 없습니다.
            이 메서드가 반환해 준 가전 목록에는 디바이스를 식별할 수 있는
            device-id가 포함되어 있으며, 이 값으로 대상 디바이스를 지정하여 다른
            메서드들을 호출할 수 있습니다.
          </td>
        </tr>
        <tr>
          <th>Parameters</th>
          <td>
            Header Parameters : <a href="#common-header">공통 Headers</a>를
            사용합니다.<br />
            Body Parameters : None
          </td>
        </tr>
        <tr>
          <th>Result</th>
          <td>
            <h3 style="margin-top: 0;">성공</h3>
            서비스가 허용한 가전 타입에 해당하는 사용자의 디바이스 목록이 다음과
            같은 형식으로 반환됩니다. 사용자가 LG ThinQ 플랫폼에 등록한 가전이
            없을 경우 비어 있는 값이 반환됩니다.

            <table class="simple-table">
              <tr>
                <th style="width: 160px">Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>deviceld</td>
                <td>String</td>
                <td>디바이스를 식별할 수 있는 ID</td>
              </tr>
              <tr>
                <td>deviceInfo</td>
                <td>Object</td>
                <td>디바이스에 대한 다음 정보를 담은 오브젝트</td>
              </tr>
              <tr>
                <td>└ deviceType</td>
                <td>Enum</td>
                <td>디바이스의 가전 타입</td>
              </tr>
              <tr>
                <td>└ modelName</td>
                <td>String</td>
                <td>디바이스의 모델 이름</td>
              </tr>
              <tr>
                <td>└ alias</td>
                <td>String</td>
                <td>디바이스 닉네임</td>
              </tr>
              <tr>
                <td>└ reportable</td>
                <td>Boolean</td>
                <td>
                  디바이스의 상태 변경 시 발생하는 event에 대해, 서비스가
                  event를 구독 중인지 여부
                </td>
              </tr>
            </table>

            <h3>실패</h3>
            공통 Responses에 정의된 응답 규약에 따라 에러 코드와 에러 메시지가
            반환됩니다.
            <h3>참고 | Device Type</h3>
            <table>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>DEVICE_AIR_CONDITIONER</td>
                <td>에어컨</td>
              </tr>
              <tr>
                <td>DEVICE_AIR_PURIFIER</td>
                <td>공기 청정기</td>
              </tr>
              <tr>
                <td>DEVICE_CEILING_FAN</td>
                <td>실링팬</td>
              </tr>
              <tr>
                <td>DEVICE_DEHUMIDIFIER</td>
                <td>제습기</td>
              </tr>
              <tr>
                <td>DEVICE_DISH_WASHER</td>
                <td>식기 세척기</td>
              </tr>
              <tr>
                <td>DEVICE_DRYER</td>
                <td>건조기</td>
              </tr>
              <tr>
                <td>DEVICE_OVEN</td>
                <td>오븐</td>
              </tr>
              <tr>
                <td>DEVICE_REFRIGERATOR</td>
                <td>냉장고</td>
              </tr>
              <tr>
                <td>DEVICE_ROBOT_CLEANER</td>
                <td>로봇 청소기</td>
              </tr>
              <tr>
                <td>DEVICE_WASHER</td>
                <td>세탁기</td>
              </tr>
              <tr>
                <td>DEVICE_STYLER</td>
                <td>스타일러</td>
              </tr>
              <tr>
                <td>DEVICE_WATER_PURIFIER</td>
                <td>정수기</td>
              </tr>
              <tr>
                <td>DEVICE_LUMI_DOOR_WINDOW_SENSOR</td>
                <td>Aqara 도어 창문센서</td>
              </tr>
              <tr>
                <td>DEVICE_LUMI_MOTION_SENSOR</td>
                <td>Aqara 모션 센서</td>
              </tr>
              <tr>
                <td>DEVICE_LUMI_TEMPERATURE_HUMIDITY_SENSOR</td>
                <td>Aqara 온습도 센서</td>
              </tr>
              <tr>
                <td>DEVICE_LUMI_PLUG</td>
                <td>Aqara 스마트 플러그</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <h2>Request 예시</h2>
      <h2 class="api-url" style="margin-top: 0;">GET ENDPOINT_URL/devices</h2>
      <pre><code class="hljs">## Header {
    "Authorization" : "Bearer 5a9a713f51a95c53d781addd1af0dfa4f6e1e7420a8bff3c5198308dac571aa9845832b8d29bbe1f04deec2d35229c6d",
    "x-country-code" : "KR", "x-message-id" : "0123456789012345678912",
}</code>
      </pre>
      <h2>Response 예시</h2>
      <pre><code class="hljs">{
    "messageId": "0123456789012345678912",
    "timestamp": "2018-02-08T06:23:20.866279",
    "response": [
          {
              "deviceId":
              "0A36FC52-6281-4954-86D5-616A58CEA2C6",
              "deviceInfo": {
                  "deviceType":"DEVICE_REFRIGERATOR",
                  "modelName": "S-TF",
                  "alias": "nickname",
                  "reportable": true
              }
          }
      ]
  }</code></pre>
      <hgroup><h1 id="device-cond">가전 상태 조회</h1></hgroup>
      <h2 class="api-url" style="margin-top: 0;">GET {Base_URL}/devices</h2>
      <table class="simple-table object">
        <tr>
          <th>Description</th>
          <td>디바이스의 현재 상태를 조회하기 위한 API입니다.</td>
        </tr>
        <tr>
          <th>Precondition</th>
          <td>
            이 메서드를 사용하기 전, device-id 값을 얻기 위해 반드시 한 번은
            디바이스 목록 조회 (GET /devices) 메서드를 호출해야 합니다.
          </td>
        </tr>
        <tr>
          <th>Parameters</th>
          <td>
            Header Parameters : 공통 Headers를 사용합니다. Body Parameters :
            None
          </td>
        </tr>
        <tr>
          <th rowspan="2">Result</th>
          <td>
            성공 디바이스의 현재 상태가 반환됩니다. 반환값은 가전별 Profile을
            기준으로 작성된 JSON 형태로 반환되며, 가전 타입마다 Profile의 구조가
            다릅니다. 가전 Profile 보기
          </td>
        </tr>
        <tr>
          <td>
            실패 공통 Responses에 정의된 응답 규약에 따라 에러 코드와 에러
            메시지가 반환됩니다.
          </td>
        </tr>
      </table>
      <h2>Request 예시</h2>
      <pre><code class="hljs">## Header
{
    "Authorization" : "Bearer 13d6800857e953e58c0a9f0cf4c1c298ecfa3012d1f9486a1925397a1f2a5d13ac6c898c9eaabda9c57ee8e8eca1006a",
    "x-country-code" : "KR",
    "x-message-id" : "0123456789012345678912",
}</code></pre>

      <h2>Response 예시</h2>
      <pre><code class="hljs">{
    "messageId": " c2d362a0-dd0f-11e6-a7c7-abfb76e3e664",
    "timestamp": " 1284101485",
    "response": ${Device Status}
}</code></pre>
      <hgroup><h1 id="device-pro">가전 Profile 조회</h1></hgroup>
      <h2 class="api-url">GET {Base_URL}/devices/profile/{device-id}</h2>
      <div class="hide">
        <h2>가전 타입 별 디바이스 프로파일 (ThinQ Connect와 동일)</h2>
        <table class="simple-table">
          <tr>
            <th>가전 타입</th>
            <th>프로파일</th>
          </tr>
          <tr>
            <td>냉장고</td>
            <td>001.ThinQ 가전 프로파일.냉장고</td>
          </tr>
          <tr>
            <td>세탁기</td>
            <td>002.ThinQ 가전 프로파일.세탁기</td>
          </tr>
          <tr>
            <td>건조기</td>
            <td>003.ThinQ 가전 프로파일.건조기</td>
          </tr>
          <tr>
            <td>에어컨</td>
            <td>004.ThinQ 가전 프로파일.에어컨</td>
          </tr>
          <tr>
            <td>공기청정기</td>
            <td>005.ThinQ 가전 프로파일.공기청정기</td>
          </tr>
          <tr>
            <td>로봇청소기</td>
            <td>006.ThinQ 가전 프로파일.로봇청소기</td>
          </tr>
          <tr>
            <td>오븐</td>
            <td>007.ThinQ 가전 프로파일.오븐</td>
          </tr>
          <tr>
            <td>식기세척기</td>
            <td>008.ThinQ 가전 프로파일.식기세척기</td>
          </tr>
          <tr>
            <td>스타일러</td>
            <td>009.ThinQ 가전 프로파일.스타일러</td>
          </tr>
        </table>
      </div>

      <table class="simple-table object">
        <tr>
          <th>Description</th>
          <td>
            디바이스의 Profile을 조회하기 위한 API입니다. 디바이스 Profile이란,
            LG 가전의 속성을 가전 타입별로 표준화하여 기술한 정보로, LG ThinQ
            플랫폼이 사용하는 디바이스 데이터입니다. 예를 들어, 냉장고에 대한
            디바이스 Profile은 온도, 냉동모드, 절전모드, 도어 열림 등 냉장고의
            상태를 설명하고 제어하기 위한 속성들을 정의합니다. 이 Profile을
            바탕으로 디바이스의 상태를 해석하고 제어 명령을 생성할 수 있습니다.
          </td>
        </tr>
        <tr>
          <th>Precondition</th>
          <td>
            이 메서드를 사용하기 전, device-id 값을 얻기 위해 반드시 한 번은
            디바이스 목록 조회(GET /devices) 메서드를 호출해야 합니다.
          </td>
        </tr>
        <tr>
          <th>Parameters</th>
          <td>
            Header Parameters : 공통 Headers를 사용합니다.<br />Body Parameters
            : None
          </td>
        </tr>
        <tr>
          <th>Result</th>
          <td>
            <h3 style="margin-top: 0;">성공</h3>
            디바이스의 가전 타입에 따라 디바이스 Profile이 JSON 형태로
            반환됩니다.
            <router-link to="/docs/thinq/profile">가전 Profile 보기</router-link>
            <h3>실패</h3>
            공통 Responses에 정의된 응답 규약에 따라 에러 코드와 에러 메시지가
            반환됩니다.
          </td>
        </tr>
      </table>
      <h2>Request 예시</h2>
      <pre><code class="hljs">## Header
{
    "authorization": "Bearer a6586272fc390566850bc5a837d32872f0c794498f651b5174244451700e2ea00ddb8dcc96902ea469aa765e589a221d",
    "x-country-code": "KR",
    "x-message-id": "0123456789012345678912",
}</code></pre>
      <h2>Response 예시</h2>
      <pre><code class="hljs">{
    "messageId": " c2d362a0-dd0f-11e6-a7c7-abfb76e3e664",
    "timestamp": "1284101485",
    "response": &lt;Device Profile&gt;
}</code></pre>
      <hgroup><h1 id="device-cont">가전 제어</h1></hgroup>
      <h2 class="api-url" style="margin-top: 0;">
        POST {Base_URL}/devices/{device-id}
      </h2>
      <table class="simple-table object">
        <tr>
          <th>Description</th>
          <td>
            LG ThinQ 플랫폼에 등록되어 있는 가전을 제어하기 위한 API입니다.
          </td>
        </tr>
        <tr>
          <th>Precondition</th>
          <td>
            이 메서드를 사용하기 전, device-id 값을 얻기 위해 반드시 한 번은
            디바이스 목록 조회(GET /devices) 메서드를 호출해야 합니다.
          </td>
        </tr>
        <tr>
          <th>Parameters</th>
          <td>
            Header Parameters : 공통 Headers를 사용합니다.<br />Body Parameters
            : 디바이스별로 다른 파라미터를 가집니다.<router-link to="/docs/thinq/profile">가전 Profile 보기</router-link>
            <pre><code class='hljs'>{
    // Device Command
}</code></pre>
          </td>
        </tr>
        <tr>
          <th>Result</th>
          <td>
            성공 Status 200 코드와 빈 값이 반환됩니다. 실패 공통 Responses에
            정의된 응답 규약에 따라 에러 코드와 에러 메시지가 반환됩니다.
          </td>
        </tr>
      </table>
      <hgroup>
        <h1 id="access-tok">EMP Access Token 발급</h1>
        <div class="note">
          ※ EMP(Enterprise Membership Platform) : LG전자 회원 플랫폼을
          의미합니다
        </div>
      </hgroup>
      <h2 class="api-url">POST {Base_URL}/token</h2>
      <table class="simple-table object">
        <tr>
          <th>Description</th>
          <td>
            ThinQ API에 사용되는 회원 계정의 Access Token을 발급하기 위한
            API입니다. ID/Password 및 Refresh Token 방식을 지원하며, 현재 LG
            Account만 지원됩니다.
          </td>
        </tr>
        <tr>
          <th>Parameters</th>
          <td>
            Header Parameters : Authorization을 제외한 공통 Headers를
            사용합니다.
            <h3>Body Parameters</h3>
            <table class="simple-table object">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>grant_type</td>
                <td>string</td>
                <td>[ password, refresh_token ]</td>
              </tr>
              <tr>
                <td>id</td>
                <td>string</td>
                <td>LG Account ID (password grant type시 필수)</td>
              </tr>
              <tr>
                <td>pasword</td>
                <td>string</td>
                <td>LG Account Password (password grant type시 필수)</td>
              </tr>
              <tr>
                <td>refresh_token</td>
                <td>string</td>
                <td>Refresh Token일 시 필수</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <th>Result</th>
          <td>
            <h3>성공</h3>
            <ul>
              <li>
                Password 방식 : Access Token, Refresh Token, Expired time(Sec.)
                반환
              </li>
              <li>
                Refresh Token 방식 : Access Token, Expired time(Sec.) 반환
              </li>
            </ul>
            <h3>실패</h3>
            아래 정의된 응답 규약에 따라 에러 코드와 에러 메시지가 반환됩니다.
            <table class="simple-table object">
              <tr>
                <th>Error Code</th>
                <th>Error Message</th>
                <th>Description</th>
              </tr>
              <tr>
                <td>412</td>
                <td>required grant_type</td>
                <td>
                  파라미터가 누락됨<br />
                  - grant_type
                </td>
              </tr>
              <tr>
                <td>412</td>
                <td>required refresh_token</td>
                <td>요청 파라미터가 누락됨<br />- refresh_token</td>
              </tr>
              <tr>
                <td>500</td>
                <td>Login Error</td>
                <td>요청 정보에 오류가 있는 경우</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <h2>Request</h2>
      <h3>password 방식</h3>
      <pre>
         <code class="hljs">## Header
{
    "x-country-code": "KR",
    "x-message-id": "0123456789012345678912",
}

## Body
{
  "grant_type": "password",
  "id": "abc@mailaddr.com"
  "password": "password"
}</code>
       </pre>
      <h3>refresh token 방식</h3>
      <pre>
         <code class="hljs">## Header
{
    "x-country-code": "KR",
    "x-message-id": "0123456789012345678912",
}

## Body
{
  "grant_type": "refresh_token",
  "refresh_token": "abcdeqjioeqoiejoqadmaomdkdlkj"
}</code>
       </pre>
      <h2>Response</h2>
      <h3>password 방식</h3>
      <pre>
         <code class="hljs">{
    "access_token":
    "7a407a61366e2d930d4414328875b3996911fd5598e34aa50c98762822a3242e2ecc06cf9252514081ddafec48916495",
    "expires_in": "3600",
    "refresh_token": "9397e5d32045b37353d94b8d42057f5e9ad8a210feacc2fb4d28a694d58fe30c42d955c15fa436825364dde96b59a1a0"
}</code>
       </pre>
      <h3>refresh_token 방식</h3>
      <pre>
         <code class="hljs">{
    "access_token":
    "7a407a61366e2d930d4414328875b3996911fd5598e34aa50c98762822a3242e2ecc06cf9252514081ddafec48916495",
    "expires_in": "3600"
}</code>
       </pre>
      <hgroup>
        <h1 id="callback">Event/Push Callback (Websocket)</h1>
      </hgroup>
      <h2 class="api-url">WSS wss://s7vajyybvf.execute-api.ap-northeast-2.amazonaws.com/websocket</h2>
      <table class="simple-table object">
        <tr>
          <th>Description</th>
          <td>
            이 메서드는 Websoket을 통하여 ThinQ 가전의 Push 및 Evnet 콜백 메시지를 수신하기 위한 API입니다.
          </td>
        </tr>
        <tr>
          <th>Parameters</th>
          <td>
            <h3>Header Parameters</h3> : 없음
            <h3>Query Parameters</h3>
            <table class="simple-table object">
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Required</th>
                <th>Description</th>
                <th>Default</th>
              </tr>
              <tr>
                <td>access_token</td>
                <td>String</td>
                <td>Mandatory</td>
                <td>LG EMP(Enterprise Membership Platform)에서 사용자 인증을 위해 OAuth 표준에 따라 발급해주는 토큰 값입니다.</td>
                <td>None</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <th>Result</th>
          <td>
            이 메서드의 호출 결과는 다음과 같이 2가지 경우(성공, 실패)가 있습니다.
            <h3>성공</h3>
            연결 성공
            <h3>실패</h3>
            실패한 경우, ThinQ Callback Websocket과의 연결이 되지 않습니다.
          </td>
        </tr>
      </table>
      <h2>Request</h2>
      <pre>
         <code class="hljs">## Query Params
WSS wss://s7vajyybvf.execute-api.ap-northeast-2.amazonaws.com/websocket?access_token=5a9a713f51a95c53d781addd1af0dfa4f6e1e7420a8bff3c5198308dac571aa9845832b8d29bbe1f04deec2d35229c6d</code>
       </pre>
      <h2>Response</h2> 없음
    </article>
    <div class="rest"></div>
  </div>
</template>
<script>
import ThinqRefSnb from "./ThinqRefSnb.vue";
export default {
  components: {
    ThinqRefSnb,
  },
  data() {
    return {
      isOpenErrorCodeList: false,
    };
  },
  methods: {
    showErrorCodeList() {
      this.isOpenErrorCodeList = true;
      this.$refs.errorCodeList.classList.remove("hide");
    },
    hideErrorCodeList() {
      this.isOpenErrorCodeList = false;
      this.$refs.errorCodeList.classList.add("hide");
      location.hash = "#errorCodeList";
    },
  },
};
</script>
